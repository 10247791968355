import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import mPinV3 from "../../../assets/images/mPinV3.svg"
import CardImg from "../../../assets/images/v3/card.png"
import mPinV3bg from "../../../assets/images/v3/mPinV3bg.svg"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import CommonService from "../../../services/CommonService"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import * as analytics from "../../../utils/analytics"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_OTP_SMS_SIZE,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
  OtpErrors,
} from "../../../utils/constants"
import {
  AuthenticationType,
  EventName,
  PwaVersions,
} from "../../../utils/enums"
import {
  captureEvents,
  getAnalyticsProgramType,
  getLastDigitsOfMobileNumber,
  goToRedirectUrl,
} from "../../../utils/functions"
import { Header } from "../../core"
import AuthHeader from "../mpin/AuthHeader"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import Info from "../../svg/info"
import styles from "./styles"
import AuthLoader from "../../core/SkeletonLoader/authLoader"
import moment from "moment"
import ActivationAuthHeader_v3 from "../../core/ActivationAuthHeader_v3"
import OtpInput from "./OtpInput_v3"
import InfoCircle from "../../svg/v3/infoCircle"
import Error_v3 from "../../core/Error_v3"
import ApiIds from "../../../auth/ApiIds"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import DividerV3 from "../../core/Divider/v3/DividerV3"

const numToString = {
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
}

const intervalTime = 30

const Otp_v3 = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const [theme, user, authStore, config, session, screen] = useSelector(
    state => [
      state.theme,
      state.user,
      state.auth,
      state.config,
      state.session,
      state.screen,
    ],
  )
  const stepNumber =
    (authStore.factors?.findIndex(
      factor => factor === authStore.currentFactor,
    ) ?? 0) + 1
  const totalAuthSteps = (authStore.factors?.length ?? 0) + 1
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [attempts, setAttempts] = useState()
  const [resendCount, setResendCount] = useState(0)
  const [seconds, setSeconds] = useState(intervalTime)
  const [otp, setOtp] = useState()
  const [pin, setPin] = useState(["", "", "", "", "", "", ""])
  const [isResendClicked, setIsResendClicked] = useState(false)
  const [slideLeft, setSlideLeft] = useState(false)
  const otpLength =
    config?.auth?.[AuthChallenges.OTP_SMS]?.length || DEFAULT_OTP_SMS_SIZE

  const interval = useRef(null)
  const otpId = useRef("")

  const maxResendCount = config?.auth?.[AuthChallenges.OTP_SMS]?.maxResendCount
  const autoOtpSubmit = config?.auth?.[AuthChallenges.OTP_SMS]?.autoSubmit

  const autoRead = config?.auth?.[AuthChallenges.OTP_SMS]?.autoRead

  const canResend = maxResendCount && maxResendCount + 1 > resendCount
  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.OTP_SMS)

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const checkForOTP = () => {
    // Check if the global variable `otpFromNative` is available
    if (window.otpFromNative) {
      // Set the OTP value
      // alert(window.otpFromNative)
      setPin(window.otpFromNative?.split(""))
      // Clear the OTP value from the global variable
      window.otpFromNative = null
    }
  }
  const isMpinSet = authStore.afaDetails.config.isMpinSet.result
  const isMpinEverSet = authStore.afaDetails.config.isMpinEverSet.result
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  // const [isVisible, setIsVisible] = useState(false)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [slideRight, setSlideRight] = useState(false)
  const slideUpAnimation = localStorage.getItem("firstTimeAuth") || false

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (autoRead) {
      const intervalId = setInterval(checkForOTP, 1000)
      return () => {
        // Clear the interval on component unmount

        clearInterval(intervalId)
      }
    }
    if (stepNumber > 1) {
      setSlideLeft(true)
    }
    setTimeout(() => {
      setSlideLeft(false)
    }, 500)
  }, [])

  const handleOTPChange = otp => {
    setError()
    setOtp(otp)
  }
  useEffect(() => {
    captureEvents({
      eventName: EventName.OTP,
      metadata: {},
    })
  }, [])

  useEffect(() => {
    if (seconds < 1) {
      clearInterval(interval.current)
    }
  }, [seconds])

  useEffect(() => {
    if (!!otp) {
      setPin(otp.split(""))
    }
  }, [otp])

  useEffect(() => {
    if (autoOtpSubmit && pin.length >= props.otp) {
      verifyOtp()
    }
  }, [pin])

  useEffect(() => {
    ;(async () => {
      try {
        // generate otp for token and scope
        setIsResendClicked(false)
        const response = await CommonService.generateChallengeOTP({
          apiToken: authStore.apiToken,
          challengeScope: authStore.currentFactor.scope,
          mobileNumber: user.customer.mobileNumber || "9911223344",
          mobileNumberCountryCode: user.customer.mobileCountryCode || "91",
        })
        const result = response.data
        if (result?.success) {
          otpId.current = result.data.mobileVerificationRefId
          setSeconds(intervalTime)
          interval.current = setInterval(() => {
            setSeconds(seconds => seconds - 1)
          }, 1000)
          if (resendCount > 0) {
            captureEvents({
              eventName: EventName.RESEND_OTP,
              metadata: {},
            })
          }
        } else {
          if (result?.errors?.blockedUntil) {
            let blockedUntilFromApi = result?.errors?.blockedUntil
            navigate("/AttemptExhausted", {
              state: {
                type: AuthenticationType.OTP,
                time: blockedUntilFromApi,
              },
            })
          } else {
            if (result?.errors?.status === OtpErrors.RETRIES_EXCEEDED) {
              setError(OtpErrors.RETRIES_EXCEEDED)
              setOtp()
            } else {
              await authStore.onAuthFailure(
                result?.errors?.reason,
                t("emailOtp.otpGenerationErrMsg"),
              )
            }
          }
        }
      } catch (error) {
        // if exception occurred call failure callback
        await authStore.onAuthFailure(error, t("emailOtp.otpGenerationErrMsg"))
      }
    })()

    if (resendCount < 1) setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendCount])

  const verifyOtp = async () => {
    localStorage.removeItem("firstTimeAuth")
    captureEvents({
      eventName: EventName.VERIFY_OTP_CTA,
      metadata: {},
    })
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - ${t("emailOtp.verifyOtpCta")}`,
    )

    let finalPin = pin.join("")
    for (let ind = 0; ind < finalPin.length; ind++) {
      let currChar = finalPin.charAt(ind)
      if (currChar < "0" || currChar > "9") {
        captureEvents({
          eventName: EventName.INCORRECT_INPUT,
          metadata: {},
        })
        return false
      }
    }

    if (pin.join("")?.length !== props.otp) {
      setError(true)

      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      return
    }

    setSubmitLoading(true)

    try {
      // verify otp for token and scope
      const response = await CommonService.verifyChallengeOTP({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        mobileNumber: user.customer.mobileNumber,
        mobileNumberCountryCode: user.customer.mobileCountryCode,
        mobileVerificationRefId: otpId.current,
        otp: pin.join(""),
      })
      const result = response.data

      if (result?.success) {
        setError(false)
        setSlideRight(true)

        // update otp ref id in verified challenges
        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          otpRefId: otpId.current,
        }

        // set verified challenges
        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        // on successful factor completion
        setTimeout(() => {
          onFactorCompletion(navigate, config?.version, true)
        }, 500)
      } else {
        if (result?.errors?.failureReason === OtpErrors.INVALID_OTP) {
          setError(OtpErrors.INVALID_OTP)
          setOtp()
          setAttempts(result?.errors?.attemptsLeft)
          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: {},
          })
        } else if (
          result?.errors?.failureReason === OtpErrors.OTP_EXPIRED &&
          canResend
        ) {
          setError(OtpErrors.OTP_EXPIRED)
          setOtp()
          let blockedUntilFromApi = result?.errors?.blockedUntil
          if (result?.errors.attemptsLeft == 0) {
            navigate("/AttemptExhausted", {
              state: {
                type: AuthenticationType.OTP,
                time: blockedUntilFromApi,
              },
            })
          }
          // config?.version !== PwaVersions.V2_1 && toast("Your OTP has expired")
        } else if (
          result?.errors?.failureReason === OtpErrors.OTP_EXPIRED &&
          !canResend
        ) {
          await authStore.onAuthFailure(
            result?.errors?.failureReason,
            t("emailOtp.otpExpiredText"),
          )
          // navigate("/Error")
        } else if (
          result?.errors?.failureReason === OtpErrors.BLOCKED_TEMPORARILY ||
          result?.errors?.failureReason === OtpErrors.TEMPORARILY_BLOCK
        ) {
          let blockedUntilFromApi = result?.errors?.blockedUntil
          navigate("/AttemptExhausted", {
            state: {
              type: AuthenticationType.OTP,
              time: blockedUntilFromApi,
            },
          })
        } else {
          // if otp verification failed due to unknow error then call failure callback
          if (result?.errors?.reason === OtpErrors.INVALID_API_TOKEN) {
            setError(OtpErrors.OTP_EXPIRED)
            setOtp()
          } else {
            await authStore.onAuthFailure(
              result?.errors?.reason,
              t("emailOtp.otpVerifyErrMsg"),
            )
          }
        }
      }
    } catch (error) {
      // if exception occurred call failure callback
      await authStore.onAuthFailure(error, t("emailOtp.genericError"))
    }

    setSubmitLoading(false)
  }
  const getClassName = () => {
    if (
      !slideRight &&
      authStore.apiId !== ApiIds.SET_MPIN &&
      authStore.factors[0].challenge === AuthChallenges.OTP_SMS &&
      slideUpAnimation
    ) {
      return "v3_mpin_slide_up"
    } else if (slideRight && !slideUpAnimation) {
      return "v3_mpin_slide_right"
    } else if (!slideUpAnimation && !slideRight && slideLeft) {
      return "v3_otp_slide_left"
    }
    return ""
  }
  return loading ? (
    <div>
      <AuthLoader />
    </div>
  ) : (
    <div
      style={{
        minHeight: window?.innerHeight,
        backgroundColor: theme.v3.cssVars.leadingWhite,
        position: "relative",
      }}
      className={getClassName()}
    >
      <Header
        onBack={async () => {
          navigate("/")
        }}
        {...(authStore.apiId === ApiIds.SET_MPIN
          ? {
              text: `${isMpinEverSet ? `Reset ${mpinWord}` : "Authentication"} - Step ${stepNumber}/${totalAuthSteps}`,
            }
          : {})}
      />
      <div
        style={
          authStore.apiId !== ApiIds.SET_MPIN
            ? { minHeight: windowHeight - 52 }
            : {}
        }
      >
        {authStore.apiId !== ApiIds.SET_MPIN ? (
          <AuthHeader
            centerFloatingImage={mPinV3}
            backgroundImage={mPinV3bg}
            type={"mpin"}
          />
        ) : (
          // <ActivationAuthHeader_v3 type='otp' />
          <React.Fragment />
        )}
        {authStore.apiId !== ApiIds.SET_MPIN ? (
          <div className='v3-header-login-otp'>
            <div className='v3_mpin_image'>
              <img src={mPinV3} alt='lock' />
            </div>
            <div className='v3-verify-otp-subtitle-login'>
              {t("emailOtp.enterOtpMobileText")}
            </div>
            {user.customer?.mobileNumber && (
              <div className='v3-verify-otp-title-login'>
                +91 XXXXXX
                {getLastDigitsOfMobileNumber(user.customer?.mobileNumber)}
              </div>
            )}
          </div>
        ) : (
          <div className='v3-header-login-otp v3-authentication-login'>
            <div className='v3-verify-otp-subtitle-login v3-authentication-login-text'>
              {t("emailOtp.verifyMobileNoText")}
            </div>
            {user.customer?.mobileNumber && (
              <div className='v3-verify-otp-title-login v3-authentication-login-mobile-text'>
                {t("emailOtp.enterOtpRegisteredMobileNoText")}
                {getLastDigitsOfMobileNumber(user.customer?.mobileNumber)}
              </div>
            )}
          </div>
        )}

        <div className='v3-otp-input-sheet-content'>
          <OtpInput
            isValid={!error}
            isResendClicked={isResendClicked}
            length={props.otp}
            onChange={e => handleOTPChange(e)}
            type={"login"}
            maxInputSize={otpLength}
          />
        </div>

        {error && (
          <>
            {error === OtpErrors.INVALID_OTP && (
              <Error_v3 type={AuthenticationType.OTP} attempts={attempts} />
            )}
            {error === OtpErrors.RETRIES_EXCEEDED && (
              <Error_v3
                errorMessage={t("emailOtp.otpRetryExhaustedTextMobile")}
              />
            )}
            {error === OtpErrors.OTP_EXPIRED && (
              <Error_v3
                errorMessage={t("emailOtp.otpExpired")}
                customClassName={"customerAuth"}
              />
            )}
          </>
        )}

        {canResend && (
          <div className='v3-verify-otp-resend-otp-text-login'>
            {seconds <= 0 && <div>{t("emailOtp.otpNotRecvdCta")}</div>}
            <div
              style={{ display: "inline" }}
              onClick={() => {
                setIsResendClicked(true)
                if (seconds < 1) {
                  setResendCount(count => count + 1)
                }
              }}
            >
              <div
                className='authSmsOtp-widget-resendOtp-timer'
                style={{
                  fontWeight: "normal",
                  display: seconds <= 0 ? "none" : "inline",
                }}
              >
                00:{seconds >= 10 ? seconds : `0${seconds}`}
              </div>
              <div
                style={{
                  color:
                    seconds <= 0
                      ? theme.v3.cssVars.tertiaryCta.textColor
                      : theme.v3.cssVars.tertiaryNeutral.color2,
                  fontWeight: seconds <= 0 ? "600" : null,
                  textDecoration: seconds <= 0 ? "underline" : null,
                }}
              >
                {t("emailOtp.resendOtpText")}
              </div>
            </div>
          </div>
        )}
        {!autoOtpSubmit && (
          <div className='v3-login-otp-cta'>
            <DividerV3 />
            <ColorButton
              text='Verify OTP'
              onPress={verifyOtp}
              isLoading={submitLoading}
              isDisable={otpLength !== otp?.length}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Otp_v3
