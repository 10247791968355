import React, { useEffect, useState } from "react"
import BlockedCardImage from "../../svg/v3/blockCardIcon.svg"
import { ColorButton } from "../../core/buttons"
import { useNavigate } from "react-router-dom"
import { captureEvents } from "../../../utils/functions"
import { useSelector } from "react-redux"
import {
  Issuers,
  AU_BlockCodes,
  Federal_BlockCodes,
  EventName,
  AccountStatus,
} from "../../../utils/enums"
import { useTranslation } from "react-i18next"
import { Header } from "../../core"
import { goToRedirectUrl } from "../../../utils/functions"

const BlockedScreen = ({ blockType }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const session = useSelector(state => state.session)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const [pageTitle, setPageTitle] = useState("")

  const description = screen.accountClosed

  useEffect(() => {
    captureEvents({ eventName: EventName.BLOCKED, metadata: {} })
  }, [])

  useEffect(() => {
    let title = ""
    let accountStatus = user.account.status
    if (
      accountStatus === AccountStatus.ACTIVE ||
      accountStatus === AccountStatus.DORMANT
    ) {
      //move ahead
    } else {
      title = t("messages.accountClosed")
    }
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      ) ||
      (session.issuer !== Issuers.AU_BANK &&
        session.issuer !== Issuers.FEDERAL_BANK &&
        (accountStatus === AccountStatus.PENDING_CLOSURE ||
          accountStatus === AccountStatus.CLOSED ||
          accountStatus === AccountStatus.CHARGE_OFF))
    ) {
      title = t("messages.accountClosed")
    } else if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.REGISTRATION_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.LOGIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      (session.issuer !== Issuers.AU_BANK &&
        session.issuer !== Issuers.FEDERAL_BANK &&
        (accountStatus === AccountStatus.SUSPENDED ||
          accountStatus === AccountStatus.FORCED_SUSPENDED))
    ) {
      title = t("messages.accountCardBlocked")
    }
    if (!title) {
      navigate("/")
    }
    setPageTitle(title)
  }, [])

  return (
    <div className='v3-blocked-screen-container'>
      <Header
        marginBottom='0px'
        text={t("messages.alert")}
        onBack={async () => {
          goToRedirectUrl()
        }}
      />
      <div className='v3-blocked-screen-body-section'>
        <div className='v3-blocked-screen-card-image'>
          <img src={BlockedCardImage} alt='Card blocked image' />
        </div>
        <div className='v3-blocked-screen-body-container'>
          <div className='v3-blocked-screen-body-header'>{pageTitle}</div>
          <div className='v3-blocked-screen-body-description-text'>
            {t("blockScreenMessages.blockScreenDescriptionText")}
          </div>
          <div className='v3-blocked-screen-body-cta-text'>
            {t("blockScreenMessages.phoneCtaText")}
          </div>
          <div className='v3-blocked-screen-body-phone'>
            {description?.moblieNo
              ? description?.moblieNo
              : t("blockScreenMessages.defaultPhoneNumber")}
          </div>
          <div className='v3-blocked-screen-body-email-header'>
            {t("blockScreenMessages.mailCtaText")}
          </div>
          <div className='v3-blocked-screen-body-email-text'>
            {description?.email
              ? description?.email
              : t("blockScreenMessages.defaultEmail")}
          </div>
        </div>
      </div>
      <div className='v3-blocked-screen-cta-container'>
        <ColorButton
          text={t("blockScreenMessages.returnToHomeText")}
          fontSize={"14px"}
          onPress={() => navigate("/")}
        />
      </div>
    </div>
  )
}

export default BlockedScreen
